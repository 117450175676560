import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'primeng/fileupload';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-upload-file',
  standalone: true,
  imports: [CommonModule, TranslateModule, FileUploadModule, SvgIconComponent],
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFileComponent {
  @Input() accept: string = '';
  @Input() maxFileSize: number = 0;
  @Input() fileLimit: number = 1;
  @Input() chooseLabel: string = 'selected_file_';
  @Input() chooseIcon: boolean = false;
  @Input() customUpload: boolean = false;
  @Input() mode: 'default' | 'img' = 'default';

  @Input() invalidFileSizeMessageSummary: string = 'the_file_';
  @Input() invalidFileSizeMessageDetail: string = 'size_exce_';
  @Input() invalidFileTypeMessage: string = 'type_file_dont_work_';
  @Input() fileLimitExceededMessage: string = 'num_limit_file_';

  @Output() onSelect = new EventEmitter<File[]>();

  @ViewChild('fileInput') fileInput: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService) {
  }

  selectedFiles: File[] = [];
  selectedFilesErrors: File[] = [];
  errorMessage: string = '';
  filePreviewUrls: { [key: string]: string } = {}; // Para almacenar las URLs de las imágenes

  openFileDialog() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any): void {
    const fileInput = event.target as HTMLInputElement;
    if (!fileInput.files || fileInput.files.length === 0) return;

    const files: FileList = event.target.files;
    this.errorMessage = ''; // Reiniciar el mensaje de error
    this.selectedFiles = []; // Reiniciar lista de archivos

    // Usamos el mensaje de error para exceder el límite de archivos
    if (files.length > this.fileLimit) {
      this.errorMessage = this.translateService.instant(this.fileLimitExceededMessage,{ limit: this.fileLimit.toString() });
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.selectedFilesErrors.push(file);
      }
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validación de tipo de archivo
      if (this.accept && !this.isFileTypeValid(file)) {
        this.errorMessage = this.translateService.instant(this.invalidFileTypeMessage,{ name: file.name });
        return;
      }

      // Validación de tamaño de archivo
      if (this.maxFileSize && file.size > this.maxFileSize) {
        const sizeInKB = (this.maxFileSize / 1024).toFixed(2);
        this.errorMessage = this.translateService.instant(this.invalidFileSizeMessageDetail,{ size: sizeInKB });
        return;
      }

      this.selectedFiles.push(file);

      // Previsualización de imágenes
      if (this.isImage(file)) {
        const reader = new FileReader();
        reader.onload = () => {
          this.filePreviewUrls[file.name] = reader.result as string;
          this.cdr.markForCheck();
        };
        reader.readAsDataURL(file);
      }
    }

    this.cdr.markForCheck();
    this.onSelect.emit(this.selectedFiles);
    fileInput.value = '';

  }

  removeFile(fileToRemove: File): void {
    this.selectedFiles = this.selectedFiles.filter(file => file !== fileToRemove);
    delete this.filePreviewUrls[fileToRemove.name];
    this.cdr.markForCheck();
  }

  removeAllFiles(): void {
    this.selectedFiles = [];
    this.filePreviewUrls= {} ;
    this.cdr.markForCheck();
  }

  getFileSize(file: File): string {
    const sizeInBytes = file.size;
    if (sizeInBytes < 1024) {
      return sizeInBytes + ' Bytes';
    } else if (sizeInBytes < 1048576) {
      return (sizeInBytes / 1024).toFixed(2) + ' KB';
    } else {
      return (sizeInBytes / 1048576).toFixed(2) + ' MB';
    }
  }

  isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  private isFileTypeValid(file: File): boolean {
    const allowedExtensions = this.accept
      .split(',')
      .map(ext => ext.trim().toLowerCase()); // Elimina espacios y pone en minúsculas

    return allowedExtensions.some(ext => file.name.toLowerCase().endsWith(ext));
  }


}
