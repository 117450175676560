<ng-container *ngIf="showM && errorsM">
  <small *ngIf="errorsM.required" data-cy="error-required">{{
    'error_.required_' | translate
  }}</small>
  <!-- <small *ngIf="errorsM.email && !messageError" data-cy="error-email">{{
    'error_.email_' | translate
  }}</small> -->
  <small *ngIf="errorsM.equalValue && !messageError" data-cy="error-equal">{{
    'error_.equal_' | translate : { error: errorsM.equalValue }
  }}</small>
  <small *ngIf="errorsM.equalSelected && !messageError" data-cy="error-equal">{{
    'error_.equalSelected_' | translate : { error: errorsM.equalValue }
  }}</small>
  <small *ngIf="errorsM.noEqualValue" data-cy="error-noEqual">{{
    'error_.noEqual_' | translate : { error: errorsM.equalValue }
  }}</small>
  <small
    *ngIf="(errorsM.pattern || errorsM.email) && !messageError"
    data-cy="error-pattern"
    >{{ 'error_.pattern_' | translate }}</small
  >
  <small *ngIf="errorsM.selectOption" data-cy="error-selectOption">{{
    'error_.selectOption_' | translate
  }}</small>
  <small *ngIf="errorsM.ageRange" data-cy="error-ageRange">{{
    'error_.ageRange_' | translate
  }}</small>
  <small *ngIf="errorsM.minLengthGroup" data-cy="error-minLengthGroup">{{
    'error_.minLengthGroup_' | translate
  }}</small>
  <small *ngIf="errorsM.minLengthGroupIryo" data-cy="error-minLengthGroup">{{
    'error_.iryo_minLengthGroup_' | translate
  }}</small>
  <small
    *ngIf="errorsM.maxCharactersChanges"
    data-cy="error-maxCharactersChanges"
    >{{ 'error_.maxCharactersChanges_' | translate }}</small
  >
  <small *ngIf="errorsM.equalValue && messageError" data-cy="error">{{
    'error_.' + messageError | translate
  }}</small>
  <small *ngIf="errorsM.pattern && messageError" data-cy="error2">{{
    'error_.' + messageError | translate
  }}</small>
  <small *ngIf="errorsM.email && messageError" data-cy="error3">{{
    'error_.' + messageError | translate
  }}</small>
  <small *ngIf="errorsM.invalidPin" data-cy="incorrect pin_">{{
    'incorrect pin_' | translate
  }}</small>
  <small *ngIf="errorsM.unsavedRequired" data-cy="error-requiredField">{{
    'error_.requiredField_' | translate
  }}</small>
  <small *ngIf="errorsM.min" data-cy="error_.min">{{
    'error_.min' | translate
  }}</small>
  <small *ngIf="errorsM.max" data-cy="error_.max">{{
    'error_.max' | translate
  }}</small>
  <small *ngIf="errorsM.minlength" data-cy="error_.min">{{
    'error_.min' | translate
  }}</small>
  <small *ngIf="errorsM.maxlength" data-cy="error_.max">{{
    'error_.max' | translate
  }}</small>
  <small *ngIf="errorsM.invalidRef" data-cy="invalidRef">{{
    'error_.invalid_ref_' | translate
  }}</small>
  <small *ngIf="errorsM.crmIsValid" data-cy="">{{
    'no found_' | translate
  }}</small>
  <small *ngIf="errorsM.duplicateID" data-cy="">{{
      'error_.duplicate_ID_' | translate
    }}</small>
  <small *ngIf="errorsM.duplicate_email" data-cy="">{{
      'error_.duplicate_Email_' | translate
    }}</small>
  <small *ngIf="errorsM.duplicate_code" data-cy="">{{
      'error_.duplicate_Code_' | translate
    }}</small>
  <small *ngIf="errorsM.greaterZero" data-cy="">{{
      'error_.greaterZero_' | translate
    }}</small>
  <small *ngIf="errorsM.minGreaterThanMax" data-cy="">{{
      'range_days_invalid_' | translate
    }}</small>
  <small *ngIf="errorsM.requireds" data-cy="">{{
      'one_required_' | translate
    }}</small>

</ng-container>
