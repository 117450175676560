import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientUtilService } from '../utils/client-util/client-util.service';
import { ActivatedRoute } from '@angular/router';
import {
  adminRol,
  languages,
  transportVertical,
} from '../../configurations/configurations';
import countries from 'src/assets/json/countries.json';
import { StorageService } from '../utils/storage.service';
import { routeQueryParams } from '../router/router.service';
import { JwtService } from './jwt.service';
import { UserStateService } from './userState.service';

interface urlParamsAutoLogin {
  userMail: string;
  userPassword: string;
  idAgent: string;
  source: string;
}

interface urlParamsAutoLoginRailApp {
  region: string;
  pcc: string;
  agentId: number;
  country: string;
}
@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUserSubject = new BehaviorSubject<UserDataModel>(null!);
  currentUser: Observable<UserDataModel>;
  httpClient: HttpClient;
  isVeci = new BehaviorSubject<boolean>(false);
  isVeciAndWorkCRMExpediente = new BehaviorSubject<boolean>(false);
  dataAutologin: urlParamsAutoLogin;
  initialStateDataAutologin = {
    userMail: '',
    userPassword: '',
    idAgent: '',
    source: '',
  };
  isAutologin: boolean;

  // constructor
  constructor(
    private http: HttpClient,
    handler: HttpBackend,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private jwt: JwtService,
    private userStateService: UserStateService
  ) {

    this.dataAutologin = this.initialStateDataAutologin;

    this.httpClient = new HttpClient(handler);

    this.currentUserSubject = new BehaviorSubject<UserDataModel>(
      this.getCurrentUserSubject()
    );
    this.currentUser = this.currentUserSubject.asObservable();

    // Update User Endpoint
    this.currentUserSubject.subscribe((currentUser) => {
      this.checkIsVeci(currentUser);
      this.checkIsVeciWithCRMandExpediente(currentUser);
      if (environment.dev_api_url) {
        environment.api_url = environment.dev_api_url;
      } else if (currentUser?.client?.ep) {
        environment.api_url = currentUser.client.ep;
      }
    });
  }

  getCurrentUserSubject() {
    let userModel = new UserDataModel().deserialize(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.userStateService.setCurrentUser(userModel);

    return userModel;
  }

  setParamsUrlAutoLogin(params: any) {
    this.isAutologin = true;
    this.dataAutologin = {
      userMail: params.e,
      userPassword: params.w,
      idAgent: params.idAgente,
      source: params.source,
    };
  }

  setParamsUrlAutoLoginRailApp(params: any): urlParamsAutoLoginRailApp {
    this.isAutologin = true;
    return {
      agentId: parseInt(params.agentId, 10),
      country: params.country,
      pcc: params.pcc,
      region: params.region,
    };
  }

  getParamsUrlAutoLogin(): urlParamsAutoLogin {
    return this.dataAutologin;
  }

  isVistatravel(): boolean {
    return this.currentUserValue?.client?.tn === 'vistatravel';
  }

  isWelcomeFLy(): boolean {
    return (
      this.currentUserValue?.client?.tn === 'vistatravel' &&
      this.currentUserValue.client?.ci === 4
    );
  }

  isPangea(): boolean {
    return (
      this.currentUserValue?.client?.tn === 'pangea' &&
      this.currentUserValue?.client?.ci === 70
    );
  }

  isVistatravelMigratedAgency(): boolean {
    return this.getAgencyId() === '24898';
  }

  isVistaTravelAgencyMigrated(): boolean {
    return true;
    /*const value = this.currentUserValue?.agency?.i
      ? parseInt(this.currentUserValue?.agency?.i)
      : 0;
    return (
      environment.vistatravel_ID_control.includes(value) &&
      !environment.vistatravelAllMigrated
    );*/
  }

  isBcdTravel(): boolean {
    return (
      this.currentUserValue?.client?.tn === 'bcdtravel' ||
      this.currentUserValue?.s?.cpn === 'bcdtravel'
    );
  }

  isGeaArgentina(): boolean {
    return this.currentUserValue?.client?.tn === 'geaargentina';
  }

  isTaunus(): boolean {
    return (
      this.currentUserValue?.client?.ci?.toString() === '154' ||
      this.currentUserValue?.client?.ci?.toString() === '230'
    );
  }

  isAvorisGroup(): boolean {
    const avorisGroups: string[] = [
      'avoris',
      'vistatravel',
      'eroski',
      'travelair',
      'globalia',
    ];

    return avorisGroups.includes(this.currentUserValue?.client?.tn);
  }

  isLleegoConsolidator(): boolean {
    return this.currentUserValue?.client?.tn === 'lleego';
  }

  showOver(): boolean {
    return this.currentUserValue?.s?.sv;
  }

  isUbico(): boolean {
    return this.currentUserValue?.client?.tn === 'ubico';
  }

  isUbicoAvailDivided(): boolean {
    const allowedAgencyIds = [6614, 6526]; // Agencies allowed
    return (this.currentUserValue?.client?.tn === 'ubico' && allowedAgencyIds.includes(Number(this.currentUserValue.agency.i)));
  }

  isNautalia(): boolean {
    return this.currentUserValue?.client?.tn === 'nautalia';
  }

  isCarrefour(): boolean {
    return this.currentUserValue?.client?.tn === 'carrefour';
  }

  isCarrefourNoIata(): boolean {
    return this.currentUserValue?.client?.ci === 182;
  }

  isIberia = (): boolean => this.userStateService.isIberia();

  haveBulkUploadMarkup(): any {
    return (
      this.currentUserValue?.client?.ci === 10 ||
      this.currentUserValue?.client?.ci === 202 ||
      this.currentUserValue?.client?.ci === 7777
    );
  }


  isRailAPP = (): boolean =>
    this.userStateService.isRailAPP()

  haveAlternativeBudget(): boolean {
    return this.isUbico();
  }

  haveFrogedChatActive(): boolean {
    return this.userStateService.haveFrogedChatActive();
  }

  getAppIdFrogedChatActive(): string {
    return this.currentUserValue?.s?.cfai;
  }

  haveDisney = (): boolean => this.currentUserValue?.s?.disney;

  haveSport = (): boolean => this.currentUserValue?.s?.sport;

  haveCupo = (): boolean => this.currentUserValue?.s?.cupo;

  cupoValue = (): boolean => this.currentUserValue?.client?.cupo;

  haveTTOO = (): boolean => this.currentUserValue?.client?.fto;

  TTOOValue = (): boolean => this.currentUserValue?.client?.ftoa;

  etnicValue = (): boolean => this.currentUserValue?.client?.etnic;

  haveCruises = (): boolean => this.currentUserValue?.s?.cruise;

  haveCarsService = (): boolean => this.currentUserValue?.s?.cars;

  haveThisService(service: string): boolean {
    let response = false;
    let option = this.currentUserValue?.s?.options.filter(
      (opt) => opt.optionName === service
    );
    if (option && option.length > 0) {
      response = true;
    }
    return response;
  }

  haveThisServiceFee(): boolean {
    return this.currentUserValue.s?.fee?.blockFeeLower;
  }

  haveServiceShowAddBalance(): boolean {
    return this.haveThisService('show_add_balance');
  }
  haveServiceShowCorporate(): boolean {
    return this.haveThisService('show_option_corporate');
  }

  haveServiceShowGhostPnr(): boolean {
    return this.haveThisService('ghost_pnr');
  }

  haveServiceShowTimeLimit(): boolean {
    return this.haveThisService('management_of_manual_time_limits');
  }

  haveServiceHideServiceFee(): boolean {
    return this.haveThisService('hide_service_fee');
  }

  haveServiceHideServiceFeettoo(): boolean {
    return this.haveThisService('hiden_service_fee_ttoo');
  }

  haveServiceShowFligthConditiones(): boolean {
    return this.haveThisService('show_flight_conditions');
  }

  /*Nuevo control expediente common */
  haveServiceShowExpedientBook(): boolean {
    return this.haveThisService('expedient_to_book');
  }
  haveServiceShowExpedientCommonBookEmit(): boolean {
    return this.haveThisService('expedient_to_emit');
  }
  haveServiceShowExpedientVoucher(): boolean {
    return this.haveThisService('expedient_in_voucher');
  }
  haveServicePatterExpedient(): boolean {
    return this.haveThisService('expedient_pattern');
  }
  haveServiceShowExpedientCommonBookLabel(): boolean {
    return this.haveThisService('expedient_label');
  }

  haveServiceShowProductTrain() {
    return this.currentUserValue?.s.trains;
  }
  /* Fin Nuevo control expediente common */

  openNewTap = (): boolean => this.currentUserValue?.client?.nt;

  showIssueFeeBreakdown(): boolean {
    if (this.currentUserValue.client.em !== undefined) {
      return this.currentUserValue.client.em;
    }
    return true;
  }

  showEmisionFeeNotIncluded = (): boolean =>
    this.currentUserValue?.client?.snemm;
  showCheckTTOO = (): boolean => this.currentUserValue?.client?.cto;

  showPaymentMethod = (): boolean => this.currentUserValue?.client?.spm;

  isAgencyCanChangeServiceFee = (): boolean =>
    this.currentUserValue.agency.ccsf!;

  defaultLang = (): string => {
    const clientCountry = this.currentUserValue?.agency?.c;
    if (!clientCountry) {
      return 'es-es';
    }

    let lang = clientCountry.toLowerCase();
    if (lang && lang?.length >= 3) {
      lang = lang.substring(0, 2);
    }
    const selectedLang = languages.find((l) => l.language === lang);
    return selectedLang ? selectedLang.value : 'es-es';
  };

  userIsOperator = (): boolean => this.currentUserValue?.user?.r === 'RO';

  userIsConsolidatorRESTRICTED = (): boolean =>
    this.currentUserValue?.user?.r === 'RCR';

  userIsConsolidatorAdmin = (): boolean =>
    this.currentUserValue?.user?.r === 'RCA';

  userIsConsolidator = (): boolean =>
    this.userStateService.userIsConsolidator();

  userIsFacturacion = (): boolean => this.userStateService.userIsFacturacion();
  userIsCallCenter = (): boolean => this.userStateService.userIsCallCenter();
  getIATACallCenter = (): string => this.currentUserValue?.client?.cci;

  userIsNego = (): boolean =>
    this.currentUserValue?.client?.tn &&
    this.currentUserValue?.client?.tn.toLowerCase() === 'nego'
      ? true
      : false;

  userIsSuperAdmin = (): boolean => this.currentUserValue?.user?.r === 'RSA';

  userIsAgency = (): boolean => this.currentUserValue?.user?.r === 'RAA';

  userIsAgent = (): boolean => this.currentUserValue?.user?.r === 'RAT';

  messageModalCanVoidAndCancel = (): boolean =>
    this.currentUserValue?.client?.cvm;

  getServiceValue(service: string): string {
    return this.currentUserValue?.s?.options.filter(
      (opt: any) => opt.optionName === service
    )[0]?.optionValue;
  }

  getFeeOptionsValue(service: string): boolean {
    return this.currentUserValue?.s?.fee[service] ?? false;
  }

  getCurrentUserObservable = (): Observable<any> =>
    this.currentUserSubject.asObservable();

  getCurrentUSer = (): UserDataModel => this.currentUserSubject.value;

  getSectionIBCC = (): string => this.currentUserValue?.user?.cc_p!;

  getProviderIATA = (): string[] =>
    this.currentUserValue?.agency?.pi ? this.currentUserValue?.agency?.pi : [];

  getUserURLHome = (): string => this.userStateService.getUserURLHome();

  getCCIataList = (): string => this.currentUserValue?.t;

  getAgencyEmail = (): string => this.currentUserValue?.agency?.e;

  getAgencyId = (): string => this.currentUserValue?.agency?.i.toString();

  getClientId = (): string => this.currentUserValue?.client?.i.toString();

  getAgencyName = (): string => this.currentUserValue?.agency?.n;

  getAgencySourceId = (): string => this.currentUserValue?.agency?.si!;

  getAgencyShowProvider = (): boolean => this.currentUserValue?.agency?.sp;

  isVoidBookingConfiguration = (): boolean => {
    return (
      !this.userIsCallCenter() ||
      !this.currentUserValue.hasOwnProperty('ibcc') ||
      (this.currentUserValue.hasOwnProperty('ibcc') &&
        this.userIsCallCenter() &&
        this.currentUserValue?.ibcc?.userAllowVoid)
    );
  };

  getCurrentUserCountry = (): string => {
    let country = this.currentUserValue.agency?.c;
    if (country?.length === 3) {
      country = countries.countries.find((c) => c.label === country)!.iso2;
    }
    return country || 'ES';
  };

  getCurrentUserCountryPrefix = (): string => {
    const country = this.currentUserValue?.agency?.c;
    let prefix = '';

    if (this.userIsCallCenter()) {
      const market = new URLSearchParams(
        new URL(window.location.href).search
      ).get('m');

      let marketToCheck = market;

      if (market === 'UK') {
        marketToCheck = 'GB';
      }

      prefix =
        countries.countries.find((c) => c.iso2 === marketToCheck)?.value ?? '';
    } else if (country && country.length === 3) {
      prefix =
        countries.countries.find((c) => c.label === country)?.value ?? '';
    } else if (country && country.length === 2) {
      prefix = countries.countries.find((c) => c.iso2 === country)?.value ?? '';
    }

    return prefix || '34';
  };

  // check if user is veci
  checkIsVeci(user): void {
    this.isVeci.next(user?.c?.tn === 'veci');
  }

  checkIsVeciWithCRMandExpediente(user): void {
    this.isVeciAndWorkCRMExpediente.next(
      user?.c?.tn === 'veci' && this.currentUserValue?.client?.ci !== 54
    );
  }

  checkIsUserVeci(): boolean {
    return this.currentUserValue?.client?.tn === 'veci';
  }

  checkIsTourMundial(): boolean {
    return this.currentUserValue?.client?.ci?.toString() === '54';
  }

  showReferencesInVoucher(): boolean {
    return this.currentUserValue?.client?.ref;
  }

  getUserSourceID(): string {
    return this.currentUserValue?.user?.si!;
  }
  getNameCPN(): string {
    return this.currentUserValue?.s?.cpn!;
  }
  getParamOfSi(): string {
    return this.currentUserValue?.a?.si ?? '';
  }

  isGlobalia(): boolean {
    const userValue: any = this.currentUserValue;
    return userValue?.c.tn === 'globalia';
  }

  checkUserData = (): boolean =>
    this.getCurrentUSer() && this.getCurrentUSer() !== null;

  // get currentUser logged in
  public get currentUserValue(): UserDataModel {
    if (
      this.currentUserSubject.value === null ||
      (!this.currentUserSubject.value?.agency &&
        (!this.currentUserSubject.value?.u?.cc_l ||
          (this.currentUserSubject.value?.u?.cc_l &&
            this.currentUserSubject.value?.u?.cc_l?.toLowerCase() !==
              'administrador')))
    ) {
      this.reloadUser();
    }
    return this.currentUserSubject.value;
  }



  // get user data from api
  getUserData(): Observable<UserDataModel> {
    // avoid getting the general error modal
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return (
      this.http
        // .get<any>('assets/mocks/currentUserRailApp.json')
        .get<any>(`${environment.api_backend_url}/api/user/data`, { headers })
        .pipe(
          map((user) => {
            this.fillUserConfig(user);
            return this.setUser(user);
          })
        )
    );
  }

  setTransportCorporateVerticalSelected(): void {
    this.storageService.setItem(
      this.currentUserValue?.getTransportVerticalKey(),
      transportVertical.corporate
    );
  }
  setTransportVacationalVerticalSelected(): void {
    this.storageService.setItem(
      this.currentUserValue?.getTransportVerticalKey(),
      transportVertical.vacational
    );
  }
  isTransportCorporateVerticalSelected(): boolean {
    const vertical = this.storageService.getItem(
      this.currentUserValue?.getTransportVerticalKey()
    );
    return (vertical && vertical === transportVertical.corporate) as boolean;
  }

  isTransportVacationalVerticalSelected(): boolean {
    const vertical = this.storageService.getItem(
      this.currentUserValue?.getTransportVerticalKey()
    );
    return (vertical && vertical === transportVertical.vacational) as boolean;
  }

  getAlerts(): Observable<any> {
    return this.http.get<any>('assets/json/alerts.json');
  }

  redirectToversionOne(): string {
    const paramsUrl = this.dataAutologin;
    return (
      paramsUrl.source +
      '/auto-login?e=' +
      paramsUrl.userMail +
      '&w=' +
      paramsUrl.userPassword +
      '&idAgente=' +
      paramsUrl.idAgent
    );
  }

  getNewsAgency() {
    return this.currentUserSubject.getValue()?.client.b ?? [];
  }

  isHalconClient(): boolean {
    return this.currentUserSubject.getValue()?.client.di;
  }

  isEroskiClient(): boolean {
    return this.currentUserSubject.getValue()?.client.tn === 'eroski';
  }

  getClientApiKey(): string {
    return this.currentUserSubject.getValue()?.client?.ak;
  }

  // set currentUser data.
  setUser(user: UserDataModel) {
    if (this.dataAutologin?.source && this.isAutologin) {
      this.isAutologin = false;
    }
    if (user?.u.r === 'RSA') {
      user.u.he = `/admin/${adminRol[user.u.r]}/home`;
    }
    localStorage.setItem('currentUser', JSON.stringify(user));
    const userModel = new UserDataModel().deserialize(user);
    this.userStateService.setCurrentUser(userModel);
    this.userStateService.setCurrentUserSubject(this.currentUserSubject);
    this.currentUserSubject.next(userModel);
    return userModel;
  }

  // reload user data
  reloadUser() {
    const user = localStorage.getItem('currentUser');
    const userModel = new UserDataModel().deserialize(JSON.parse(user!));
    if (!this.deepEqual(userModel, this.currentUserSubject.value)) {
      this.userStateService.setCurrentUser(userModel);
      this.currentUserSubject.next(userModel);
    }

    return userModel;
  }

  deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 == null ||
      obj2 == null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  // Get user client name
  getClientName(): string {
    if (this.currentUserSubject?.getValue()?.client?.tn) {
      return this.currentUserSubject.getValue().client.tn;
    } else {
      return ClientUtilService.guessClientName();
    }
  }

  private fillUserConfig(user: any): void {
    let userLocalStorage;
    if (!user) {
      return;
    }
    const params: routeQueryParams = this.route.snapshot.queryParams;
    let config: any = null;
    if (params.idCRM) {
      config = {
        ...config,
        idCRM: params.idCRM,
      };
    }
    if (params.idEXP) {
      config = {
        ...config,
        idEXP: params.idEXP,
      };
    }
    if (localStorage.getItem('currentUser')) {
      userLocalStorage = JSON.parse(localStorage.getItem('currentUser')!);
    }
    if (
      config ||
      this.currentUserValue?.config?.idCRM ||
      userLocalStorage?.config?.idCRM
    ) {
      user.config = config || {
        idCRM:
          this.currentUserValue?.config?.idCRM ?? userLocalStorage.config.idCRM,
      };
    }
  }

  getOptionExpedient() {
    const option = this.currentUserValue.s.options;
    return option;
  }

  avoidUsa = (): boolean => this.currentUserValue?.s?.prefilters?.f?.au;

  publicFare = (): boolean => this.currentUserValue?.s?.prefilters?.f?.pf;

  baggage = (): boolean => this.currentUserValue?.s?.prefilters?.f?.b;

  availDivided = (): boolean =>
    this.currentUserValue?.s?.prefilters?.f?.ad ?? true;

  duplicated = (): boolean => this.currentUserValue?.s?.prefilters?.f?.d;

  getProvidersList = (): any[] => this.currentUserValue?.p;

  getUserCurrency = (): string => this.currentUserValue?.client?.cfee;

  getDoubleCurrency(): string[] {
    return this.currentUserSubject.getValue()?.c?.dc ?? [];
  }

  userIsLleegoInternal = (): boolean => {
    const decodedToken = this.storageService.decodeToken(
      localStorage.getItem('token') ?? ''
    );
    return decodedToken.roles?.includes('ROLE_LLEEGO_INTERNAL');
  };

  isOnlyBooking(): boolean {
    const decodedToken = this.jwt.decodeToken(
      this.storageService.getItem('token') ?? ''
    );

    return decodedToken.ob;
  }

  // destroy user
  destroyUser(): void {
    this.userStateService.destroyUser();
  }
}
