<div class="file-upload" [ngClass]="{backGroundChoseIcon:(chooseIcon && selectedFiles.length === 0), backGroundFavicon: accept === '.favicon'}" >
  <button *ngIf="!chooseIcon" class="button-open" type="button" (click)="openFileDialog()">
    {{ chooseLabel | translate}}
  </button>
  <div *ngIf="chooseIcon && (mode === 'default' || (mode === 'img' && selectedFiles.length === 0))" class="image-button-box">
    <div  class="button-open-span" [ngClass]="{faviconButton: accept === '.favicon'}"  (click)="openFileDialog()">
      <span *ngIf="accept !== '.favicon'">{{chooseLabel |translate}}</span>
    </div>
  </div>

  <ul *ngIf="selectedFiles.length > 0" class="p-listbox p-component">
    <li *ngFor="let file of selectedFiles" class="p-listbox-item">
      <div class="file-name" *ngIf="mode === 'default'; else imagenPreviewOptionTemplate">
        <div class="names">
          <p>{{ file.name }}</p>
          <p class="pl-3">{{ getFileSize(file) }}</p>
        </div>
        <div class="accion-delete">
          <button type="button" (click)="removeFile(file)">
            <span class="">x</span>
          </button>
        </div>
      </div>
      <ng-template #imagenPreviewOptionTemplate>
        <div class="file-preview">
          <p><b>{{'name_' | translate }}:{{ file.name }}</b></p>
          <div *ngIf="filePreviewUrls[file.name]" class="img-box">
            <img *ngIf="isImage(file) && accept !== 'svg'" [src]="filePreviewUrls[file.name]" alt="Vista previa" />
            <svg-icon *ngIf="isImage(file) && accept === 'svg'" [src]="filePreviewUrls[file.name]" alt="Vista previa"></svg-icon>
            <span>{{'size_' |translate}}:{{ getFileSize(file) }}</span>
          </div>
        </div>
      </ng-template>

    </li>
  </ul>

  <input type="file" #fileInput (change)="onFileSelected($event)" hidden [accept]="accept" />
</div>
<p *ngIf="errorMessage" class="error-message" [ngClass]="{imgError:mode === 'img'}">
  <svg-icon></svg-icon> {{ errorMessage |translate }}
</p>
